export enum AITextSourceType {
    Keywords = 'keywords',
    Document = 'document',
}

export enum AISupportedUsage {
    Colleagues = 'Colleagues',
    Customers = 'Customers',
    Students = 'Students',
    Friends = 'Friends',
    Other = 'Other',
}

export enum AISupportedLength {
    Summarized = 'Summarized',
    Informative = 'Informative',
    Detailed = 'Detailed',
}

export enum AISupportedToneOfVoice {
    Neutral = 'neutral',
    Casual = 'casual',
    Persuasive = 'persuasive',
    Intellectual = 'intellectual',
}

export enum AISupportedTypes {
    Presentation = 'presentation',
    Quiz = 'quiz',
}

export enum AIGenerateCheckStatus {
    Queued = 'queued',
    Working = 'working',
    Done = 'done',
    Error = 'error',
}

export enum AiContentCreatorStep {
    AddKeywords = 'AddKeywords',
    SelectType = 'SelectType',
    AddSlide = 'AddSlide',
}

export enum AIWriteFor {
    Business = 'Business',
    HighSchoolers = 'HighSchoolers',
    CollegeStudents = 'CollegeStudents',
    Creatives = 'Creatives',
    TechEnthusiasts = 'TechEnthusiasts',
    Colleagues = 'Colleagues',
    Family = 'Family',
    Everyone = 'Everyone',
}

export enum AIToneOfVoice {
    Neutral = 'Neutral',
    Casual = 'Casual',
    Professional = 'Professional',
    Conversational = 'Conversational',
    Technical = 'Technical',
    Academic = 'Academic',
    Humorous = 'Humorous',
}

export enum AIAmountOfTextOnSlides {
    Brief = 'Brief',
    Medium = 'Medium',
    Detailed = 'Detailed',
}

export interface AIPresentationStepperData {
    initialPrompt: string;
    language: string | null;
    usage: AISupportedUsage | null;
    length: AISupportedLength | null;
    toneOfVoice: AISupportedToneOfVoice | null;
    type: AISupportedTypes | null;
    titleSuggestions: Array<string>;
    title: string | null;
    useOwnTitle: boolean;
    ownTitle: string;
    withInteractiveQuestions: boolean;
}

export interface AIState {
    aiStepper: AIPresentationStepperData;
    aiGuidedTourRunning: boolean;
    aiGuidedTourStep: number;
    aiContentCreatorKeywords: string,
    aiContentCreatorStep: AiContentCreatorStep,
}

export interface AILanguage {
    name: string,
    isoCode: string | null,
    country: string | null,
    isPremium: boolean;
}

export interface StepperLengthConfigItem {
    name: AISupportedLength;
    displayValueTranslationKey: string;
    numberOfSlides: number;
    lengthInMinutes: number;
}

export const StepperLengthConfig: Record<AISupportedLength, StepperLengthConfigItem> = {
    [AISupportedLength.Summarized]: {
        name: AISupportedLength.Summarized,
        displayValueTranslationKey: "SUMMARIZED",
        numberOfSlides: 6,
        lengthInMinutes: 10,
    },
    [AISupportedLength.Informative]: {
        name: AISupportedLength.Informative,
        displayValueTranslationKey: "INFORMATIVE",
        numberOfSlides: 12,
        lengthInMinutes: 20,
    },
    [AISupportedLength.Detailed]: {
        name: AISupportedLength.Detailed,
        displayValueTranslationKey: "DETAILED",
        numberOfSlides: 15,
        lengthInMinutes: 30,
    }
}

export enum AIDocumentStepperSteps {
    DocumentUpload,
    Title,
    ToneOfVoice,
    Language,
    Outline,
}

export enum GenerationStep {
    EditTitle,
    EditTemplates,
}